@import "variables";
@import "bootstrap-sizing";

// .settings-container {
//     background-color: white;
//     padding-left: 40px;
//     padding-right: 40px;
//     padding-top: 60px;
//     padding-bottom: 60px;
//     margin-left: 40px;
//     margin-right: 40px;
// }

.form-input-group {
  width: 40%;
  margin: 0 auto;
  text-align: center;

  h2 {
    color: $sidebarBackground;
    margin-bottom: 20px;
  }

  @include breakpoint(xs) {
    width: 95%;
  }
  @include breakpoint(sm) {
    width: 95%;
  }
  @include breakpoint(md) {
    width: 70%;
  }

  input,
  button {
    height: 48px;
    width: 100%;
    border-radius: 24px;
    margin-bottom: 15px;
  }
}
