// Sizing
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

// Body
$bodyBackground: #211549;

// Typography
$fontFamily: "Montserrat", sans-serif;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;

//Colors
$themeColor: #faa61a;
$themeLightColor: rgba(250, 166, 26, 0.4);
$sidebarBackground: #a3057f;
$sidebarFill: #840467;
$sidebarFillSelected: #faa61a;
$statsTextColor: $sidebarBackground;
$statsNameTextColor: #22164a;
$inboundSnapBackground: white;
$inboundSnapBorder: #e8eff7;
$trayButtonColor: #9600da;
$trayColor: #180f3c;
$promotionBackgroundColor: #211649;
$textBoxBorderColor: #dbdbdb;
